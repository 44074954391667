<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>运营管理</a-breadcrumb-item>
    <a-breadcrumb-item>微小播放器配置</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 名称 -->
    <span class="tool-title">微小：</span>
    <a-input v-model:value="search" style="width: 160px;" placeholder="请输入微小名称" />
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="handleSearch">查询</a-button>
    <a-button style="margin-left: 16px;" @click="handleReset">重置</a-button>
    <div style="flex: 1;"></div>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 分享状态 -->
      <template v-if="column.key === 'share_status'">
        <a-switch
          v-model:checked="record.share_status"
          checked-children="开"
          un-checked-children="关"
          @change="statusChange($event, record)"
        />
      </template>
      <!-- 剧尾推荐位 -->
      <template v-if="column.key === 'end_rec_position_status'">
        <a-switch
          v-model:checked="record.end_rec_position_status"
          checked-children="开"
          un-checked-children="关"
          @change="statusChangeRecommend($event, record, 1)"
        />
      </template>
      <!-- 选集推荐位 -->
      <template v-if="column.key === 'drama_rec_position_status'">
        <a-switch
          v-model:checked="record.drama_rec_position_status"
          checked-children="开"
          un-checked-children="关"
          @change="statusChangeRecommend($event, record, 2)"
        />
      </template>
      <!-- 简介推荐位 -->
      <template v-if="column.key === 'desc_rec_position_status'">
        <a-switch
          v-model:checked="record.desc_rec_position_status"
          checked-children="开"
          un-checked-children="关"
          @change="statusChangeRecommend($event, record, 3)"
        />
      </template>
      <!-- 绑定公众号 -->
      <template v-if="column.key === 'bind_account'">
        <span v-if="record.bind_account" style="margin-right: 8px;">{{ record.config_wechat.name }}</span>
        <a class="operation-item" @click="touchBind(record)">{{ record.bind_account ? '解绑' : '绑定' }}</a>
      </template>
    </template>
  </a-table>
  <!-- 绑定公众号 -->
  <Bind ref="RefBind" @success="getList"></Bind>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import Bind from './components-wxplayer/Bind'
import { message, Modal } from 'ant-design-vue'
import { wechatConfigList, wechatShare, wechatRecPosition, wechatUnbindAccount } from '@/api/copyright'

// 绑定公众号
let RefBind = ref(null)
// 加载
let isLoading = ref(false)
// 搜索
let search = ref(undefined)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '微小名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '分享开关',
    dataIndex: 'share_status',
    key: 'share_status'
  },
  {
    title: '剧尾推荐位',
    dataIndex: 'end_rec_position_status',
    key: 'end_rec_position_status'
  },
  {
    title: '选集推荐位',
    dataIndex: 'drama_rec_position_status',
    key: 'drama_rec_position_status'
  },
  {
    title: '简介推荐位',
    dataIndex: 'desc_rec_position_status',
    key: 'desc_rec_position_status'
  },
  {
    title: '绑定公众号',
    dataIndex: 'bind_account',
    key: 'bind_account'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取列表
  getList()
})

// 获取列表
function getList () {
  isLoading.value = true
  const params = {
    keywords: search.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  wechatConfigList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data.data
      list.forEach(item => {
        item.share_status = Boolean(item.config_wechat.share_status)
        item.bind_account = Boolean(item.config_wechat.bind_account)
        item.desc_rec_position_status = Boolean(item.config_wechat.desc_rec_position_status)
        item.end_rec_position_status = Boolean(item.config_wechat.end_rec_position_status)
        item.drama_rec_position_status = Boolean(item.config_wechat.drama_rec_position_status)
      })
      dataSource.value = list
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 绑定公众号
function touchBind (record) {
  if (record.bind_account) {
    // 有绑定进行解绑
    Modal.confirm({
      title: '提示',
      content: `确定要解除公众号【${record.config_wechat.name}】绑定吗?`,
      cancelText: '取 消',
      okText: '确 定',
      onOk: () => {
        wechatUnBindAccount(record)
      }
    })
  } else {
    // 无绑定进行绑定
    RefBind.value.showDrawer(record || {})
  }
}

// 公众号解绑
function wechatUnBindAccount (record) {
  isLoading.value = true
  const params = {
    project_id: record.id,
    name: record.config_wechat.name,
    mp_id: record.config_wechat.mp_id
  }
  wechatUnbindAccount(params).then(res => {
    // isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      message.success('解除绑定成功')
    } else {
      message.error(res.message || msg)
    }
    getList()
  }).catch(() => {
    // isLoading.value = false
    getList()
  })
}

// 查询
function handleSearch () {
  pagination.current = 1
  // 获取列表
  getList()
}

// 重置
function handleReset () {
  // 筛选项重置
  search.value = null
  // 分页重置
  pagination.current = 1
  getList()
}

// 切换启用状态
function statusChange (e, record) {
  isLoading.value = true
  const params = {
    project_id: record.id,
    status: Number(e)
  }
  wechatShare(params).then(res => {
    // isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      if (e) {
        message.success('开启成功')
      } else {
        message.success('关闭成功')
      }
    } else {
      message.error(res.message || msg)
    }
    getList()
  }).catch(() => {
    // isLoading.value = false
    getList()
  })
}

// 推荐位开关 entryType：1 - 剧结束 2 - 选集最右侧推荐 3 - 剧集profile页相关推荐
function statusChangeRecommend (e, record, entryType) {
  isLoading.value = true
  const params = {
    project_id: record.id,
    entry_type: entryType,
    status: Number(e)
  }
  wechatRecPosition(params).then(res => {
    // isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      if (e) {
        message.success('开启成功')
      } else {
        message.success('关闭成功')
      }
    } else {
      message.error(res.message || msg)
    }
    getList()
  }).catch(() => {
    // isLoading.value = false
    getList()
  })
}
</script>

<style scoped>
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.table-view {
  margin-top: 20px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>